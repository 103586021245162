import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// MUI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

// Icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

// Other Imports
import PasswordStrengthBar from '../../components/PasswordStrengthBar';
import { UserLoginInputContext } from '../../lib/contextLib';

import { checkForEmailErrors } from '../../utils';
import { createFetchHeaders, checkForDuplicateEmail, checkIfEmailIsVerified } from '../../utils/apiCalls';

import './SignUpFlow.scss';

export default function SignUpFlow({ currentStep, setCurrentStep, setValidationCodeSentEmail }) {
  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);

  // Step #1
  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);
  const [checkingForEmailErrors, setCheckingForEmailErrors] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');

  const [showEmailVerifiedErrorHeader, setShowEmailVerifiedErrorHeader] = useState(false);
  const [isSendingNewCode, setIsSendingNewCode] = useState(false);

  // Step #2
  const [companyName, setCompanyName] = useState('');
  const [companyNameInputHasError, setCompanyNameInputHasError] = useState(false);

  // Step #3
  const [sendNewsletterIsChecked, setSendNewsletterIsChecked] = useState(false);
  const [userAgreementIsChecked, setUserAgreementIsChecked] = useState(false);
  const [showUserAgreementError, setShowUserAgreementError] = useState(false);
  const [submittingSignUp, setSubmittingSignUp] = useState(false);

  const nav = useNavigate();

  async function resendVerificationCode() {
    setIsSendingNewCode(true);
    try {
      await Auth.resendSignUp(userLoginInput.email);
      setValidationCodeSentEmail(userLoginInput.email);
      setUserLoginInput({ email: '', password: '' });
    } catch (e) {
      alert(e);
    } finally {
      setIsSendingNewCode(false);
    }
  }

  async function checkEmailInput() {
    setCheckingForEmailErrors(true);
    let { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    if (!errorStatus) {
      errorStatus = await checkForDuplicateEmail(userLoginInput.email);
      if (errorStatus) {
        const isEmailVerified = await checkIfEmailIsVerified(userLoginInput.email);
        if (!isEmailVerified) {
          setShowEmailVerifiedErrorHeader(true);
          errorMessage = (
            <span>
              Must verify email address.
              {' '}
              <Button
                className="resend-code-error-link"
                disabled={isSendingNewCode}
                onClick={() => resendVerificationCode()}
              >
                Resend verification code
              </Button>
            </span>
          );
        } else {
          errorMessage = (
            <span>
              This email is already associated with an account.
              {' '}
              <Button className="forgot-password-error-link" onClick={() => nav('/login/reset')}>Forgot password?</Button>
            </span>
          );
        }
      }
    }
    setEmailInputHasError(errorStatus);
    setEmailErrorMessage(errorMessage);
    setCheckingForEmailErrors(false);
  }

  useEffect(() => {
    if (userLoginInput.email) checkEmailInput();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (!userAgreementIsChecked) {
      setShowUserAgreementError(true);
      return;
    }

    setSubmittingSignUp(true);

    try {
      const cognitoResponse = await Auth.signUp({
        username: userLoginInput.email,
        password: userLoginInput.password,
        attributes: {
          email: userLoginInput.email,
        },
      });
      const acctData = {
        cognitoUuid: cognitoResponse.userSub,
        isClient: 1,
        email: userLoginInput.email,
        companyName,
        marketingOptIn: sendNewsletterIsChecked,
        isAuthSignatory: true,
      };
      try {
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/accounts?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
          await createFetchHeaders('post', acctData),
        );
      } catch (e) {
        alert(e);
      }
      setValidationCodeSentEmail(userLoginInput.email);
      setUserLoginInput({ email: '', password: '' });
    } catch (e) {
      alert(e);
    } finally {
      setSubmittingSignUp(false);
    }
  }

  if (currentStep === 1) {
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!userLoginInput.email) {
            setEmailInputHasError(true);
            setEmailErrorMessage('Please enter your email address.');
            return;
          }
          if (emailInputHasError || checkingForEmailErrors) return;
          setCurrentStep(currentStep + 1);
        }}
      >
        {showEmailVerifiedErrorHeader && emailInputHasError && (
          <div className="email-verified-error-header">
            <WarningIcon />
            <p>
              This email address is already associated with an account,
              but the email address hasn&apos;t been verified.
              To proceed,
              {' '}
              <Button
                className="resend-code-error-link in-header"
                disabled={isSendingNewCode}
                onClick={() => resendVerificationCode()}
              >
                resend verification code
              </Button>
              {' '}
              to this email address.
            </p>
          </div>
        )}
        <TextField
          label="Email"
          value={userLoginInput.email}
          error={emailInputHasError}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
          onFocus={() => setEmailInputHasError(false)}
          onBlur={() => userLoginInput.email && checkEmailInput()}
          helperText={emailInputHasError ? (
            <>
              <ErrorIcon />
              {emailErrorMessage}
            </>
          ) : 'Use your company email address'}
        />

        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthBar
          passwordInput={userLoginInput.password}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />

        <Button
          type="submit"
          variant="contained"
          className="continue-button"
          // disabled={passwordStrength === 'Weak'}
        >
          Continue
        </Button>
        <Button
          className="login-btn"
          onClick={() => nav('/')}
        >
          Already have an account? Login
        </Button>
      </form>
    );
  }

  if (currentStep === 2) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!companyName) {
            setCompanyNameInputHasError(true);
            return;
          }
          setCurrentStep(currentStep + 1);
        }}
      >
        <TextField
          label="Company name"
          value={companyName}
          error={companyNameInputHasError}
          onChange={(e) => setCompanyName(e.target.value)}
          onFocus={() => setCompanyNameInputHasError(false)}
          helperText={companyNameInputHasError ? (
            <>
              <ErrorIcon />
              Please enter your company name
            </>
          ) : 'Please use your company\'s full legal name'}
        />
        <Button type="submit" className="continue-button">
          Continue
        </Button>
        <Button
          className="back-btn"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </Button>
      </form>
    );
  }

  if (currentStep === 3) {
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="checkbox email-marketing">
          <Checkbox
            checked={sendNewsletterIsChecked}
            onChange={() => setSendNewsletterIsChecked(!sendNewsletterIsChecked)}
            icon={<div className="unchecked-icon" />}
            checkedIcon={(
              <div className="checked-icon">
                <CheckRoundedIcon />
              </div>
            )}
          />
          <span className="email-marketing-text">
            Yes, email or text me so that I can receive the
            latest news and information about events,
            products and services from initio.
          </span>
        </div>
        <div className="checkbox user-agreement-and-privacy">
          <Checkbox
            checked={userAgreementIsChecked}
            onChange={() => {
              setUserAgreementIsChecked(!userAgreementIsChecked);
              setShowUserAgreementError(false);
            }}
            icon={<div className="unchecked-icon" />}
            checkedIcon={(
              <div className="checked-icon">
                <CheckRoundedIcon />
              </div>
            )}
          />
          <span className="user-agreement-and-privacy-text">
            By creating and signing in to an initio account,
            I acknowledge that I have reviewed the initio
            <br />
            <a
              className="conditions-link"
              href="https://initio-frontend-assets.s3.us-west-2.amazonaws.com/Initio_Software_Inc.-Terms_and_Conditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions for Services
              {' '}
              <OpenInNewIcon />
            </a>
            {' '}
            and agree to be bound by the terms and conditions therein.
          </span>
        </div>
        {showUserAgreementError && (
          <div className="user-agreement-error">
            <ErrorIcon />
            You must accept the &lsquo;Terms and Conditions for Services&rsquo; to proceed.
          </div>
        )}

        <Button
          type="submit"
          variant="contained"
          disabled={submittingSignUp}
          className="sign-up-button"
        >
          {submittingSignUp ? <LoadingSpinner className="loading-spinner" /> : 'Sign up'}
        </Button>
        <Button
          className="back-btn"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </Button>
      </form>
    );
  }
}

SignUpFlow.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setValidationCodeSentEmail: PropTypes.func.isRequired,
};
