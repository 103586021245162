import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './pages/Login/Login';
import Signup from './pages/SignUp/SignUp';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import EnterpriseSignUp from './pages/EnterpriseSignUp/EnterpriseSignUp';
import InvestorSignUp from './pages/InvestorSignUp/InvestorSignUp';

export default function RouterProvider() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        exact
        path="/signup"
        element={<Signup />}
      />
      <Route
        exact
        path="/verify-email"
        element={<VerifyEmail />}
      />
      <Route
        exact
        path="/login/reset"
        element={<ResetPassword />}
      />
      <Route
        exact
        path="/enterprise-signup"
        element={<EnterpriseSignUp />}
      />
      <Route
        exact
        path="/investor-signup"
        element={<InvestorSignUp />}
      />
      {/* Catch all unmatched routes */}
      <Route path="*" element={<h3 style={{ textAlign: 'center', marginTop: '24px' }}>Sorry, page not found</h3>} />
    </Routes>
  );
}
