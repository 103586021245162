import { Auth } from 'aws-amplify';

export async function createFetchHeaders(method, body, addAuthToken) {
  const authHeaders = { method, headers: { 'Content-Type': 'application/json' }, ...(Object.keys(body).length && { body: JSON.stringify(body) }) };

  if (addAuthToken) {
    const currentUserSession = await Auth.currentSession();
    const JWT = currentUserSession.getIdToken().getJwtToken();
    authHeaders.headers.Authorization = `Bearer ${JWT}`;
  }

  return authHeaders;
}

export async function checkForDuplicateEmail(email) {
  try {
    const emailDedupe = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/accounts/duplicates/${email}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
    );
    const { isAlreadyUser } = await emailDedupe.json();
    if (isAlreadyUser) return true;
  } catch (e) {
    alert(e);
  }
  return false;
}

export async function checkIfEmailIsVerified(email) {
  try {
    const isEmailVerified = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/accounts/is-verified/${email}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
    );
    const { isVerified, userExists } = await isEmailVerified.json();
    if (userExists && !isVerified) return false;
  } catch (e) {
    alert(e);
  }
  return true;
}

export default {
  createFetchHeaders,
  checkForDuplicateEmail,
  checkIfEmailIsVerified,
};
