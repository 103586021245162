import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import PasswordStrengthBar from '../../components/PasswordStrengthBar';


import { ErrorMessageContext } from '../../lib/contextLib';

import './EnterpriseSignUp.scss';
import { createFetchHeaders } from '../../utils/apiCalls';

export default function EnterpriseSignUp() {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [userData, setUserData] = useState({});
  const [userPassword, setUserPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const email = urlParamsOnPgLoad.get('email');
    const firstName = urlParamsOnPgLoad.get('firstName');
    const lastName = urlParamsOnPgLoad.get('lastName');
    const phoneNumber = urlParamsOnPgLoad.get('phoneNumber');
    const companyId = urlParamsOnPgLoad.get('companyId');
    setUserData({
      email,
      firstName,
      lastName,
      phoneNumber,
      companyId,
    });
  }, []);

  async function finalizeEnterpriseUser(event) {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      await Auth.signUp({
        username: userData.email, password: userPassword,
        attributes: {
          email: userData.email,
          'custom:firstName': userData.firstName,
          'custom:lastName': userData.lastName,
          'custom:phoneNumber': userData.phoneNumber,
          'custom:userType': 'enterprise',
          'custom:companyId': userData.companyId,
          'custom:firstTimeExp': '1',
        },
      });
      const user = await Auth.signIn(userData.email, userPassword);
      const { sub } = user.signInUserSession.idToken.payload;
      const accountData = {
        enterpriseCompanyId: userData.companyId,
        accountId: sub,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        companyId: userData.companyId,
      };
      await fetch(
        `${process.env.REACT_APP_ENTERPRISE_BACKEND_URL}/accounts/update-verified-enterprise-account`,
        await createFetchHeaders('post', accountData, true),
      );
      await Auth.signIn(userData.email, userPassword);
      window.location.replace(`${process.env.REACT_APP_ENTERPRISE_HOSTNAME}/first-enterprise-sign-in`);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsLoggingIn(false);
    }
  }

  return (
    <main className="EnterpriseSignUp">
      <h4>Create password to continue</h4>
      <div className="success-header">
        <div className="check-icon">
          <CheckRoundedIcon />
        </div>
        <p>Great, let&apos;s get started! Simply create a password to login.</p>
      </div>
      <form onSubmit={(e) => finalizeEnterpriseUser(e)}>
        <TextField
          label="Email"
          className="email-field"
          InputLabelProps={{ shrink: true }}
          value={userData.email || ''}
          disabled
        />
        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userPassword || ''}
          onChange={(e) => setUserPassword(e.target.value)}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordStrengthBar
          passwordInput={userPassword}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />
        <Button
          type="submit"
          className="confirm-login-btn"
          disabled={isLoggingIn || passwordStrength === 'Weak'}
        >
          {isLoggingIn ? <LoadingSpinner className="loading-spinner" /> : 'Login'}
        </Button>
      </form>
    </main>
  );
}
