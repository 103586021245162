import React, { useState, useEffect } from 'react';

import NavBar from './components/NavBar';
import RouterProvider from './Routes';
import AlertDisplay from './components/AlertDisplay';

import './App.scss';

function App() {
  const [isSigningOut, setIsSigningOut] = useState(true);

  async function clearAuthCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${process.env.REACT_APP_COOKIE_STORAGE_DOMAIN}`;
    }
    setIsSigningOut(false);
  }

  useEffect(() => { clearAuthCookies(); }, []);

  if (!isSigningOut) {
    return (
      <div className="App">
        <AlertDisplay />
        <NavBar />
        <RouterProvider />
      </div>
    );
  }
}

export default App;
