import React, { useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import PasswordStrengthBar from '../../components/PasswordStrengthBar';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { UserLoginInputContext, ErrorMessageContext } from '../../lib/contextLib';

import { checkForEmailErrors } from '../../utils';

import './ResetPassword.scss';

export default function ResetPassword() {
  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [resetCodeHasBeenSent, setResetCodeHasBeenSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationEmail, setVerificationEmail] = useState('');
  const [isSubmittingReset, setIsSubmittingReset] = useState(false);
  const [isSendingResetEmail, setIsSendingResetEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [successfulPasswordReset, setSuccessfulPasswordReset] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const nav = useNavigate();


  function checkEmailInput() {
    const { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    setEmailInputHasError(errorStatus);
    setEmailErrorMessage(errorMessage);
  }

  useEffect(() => {
    if (userLoginInput.email) checkEmailInput();

    // Retrieve reset password information
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const urlVerificationCode = urlParamsOnPgLoad.get('verificationCode');
    const urlVerificationEmail = urlParamsOnPgLoad.get('userEmail');
    if (urlVerificationCode) setVerificationCode(urlVerificationCode);
    if (urlVerificationEmail) setVerificationEmail(urlVerificationEmail);
  }, []);

  async function handleSendResetCode(event) {
    event.preventDefault();
    setIsSendingResetEmail(true);
    try {
      await Auth.forgotPassword(userLoginInput.email || verificationEmail);
      setResetCodeHasBeenSent(true);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsSendingResetEmail(false);
    }
  }

  async function handleResetPassword(event) {
    event.preventDefault();
    setIsSubmittingReset(true);
    try {
      await Auth.forgotPasswordSubmit(
        verificationEmail,
        verificationCode,
        userLoginInput.password,
      );
      setSuccessfulPasswordReset(true);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsSubmittingReset(false);
    }
  }

  async function signIn() {
    setIsSigningIn(true);
    try {
      await Auth.signIn(verificationEmail, userLoginInput.password);
      setUserLoginInput({ email: '', password: '' });
      nav('/');
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsSigningIn(false);
    }
  }

  if (successfulPasswordReset) {
    return (
      <div className="ResetPassword">
        <div className="success-message">
          <div className="check-icon"><CheckRoundedIcon /></div>
          <span>Password successfully reset</span>
        </div>
        <Button
          className="return-to-portal"
          disabled={isSigningIn}
          onClick={() => signIn()}
        >
          {isSigningIn ? <LoadingSpinner className="loading-spinner" /> : 'Navigate to portal'}
        </Button>
      </div>
    );
  }

  if (verificationCode) {
    return (
      <form className="ResetPassword" onSubmit={(e) => handleResetPassword(e)}>
        <h4>Reset password</h4>
        <h5>{verificationEmail}</h5>
        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthBar
          passwordInput={userLoginInput.password}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />

        <Button
          className="confirm-reset-btn"
          type="submit"
          disabled={isSubmittingReset || passwordStrength === 'Weak'}
        >
          {isSubmittingReset ? <LoadingSpinner className="loading-spinner" /> : 'Reset'}
        </Button>
        <Button
          className="send-new-link"
          disabled={isSendingResetEmail}
          onClick={() => handleSendResetCode()}
        >
          Send new reset link
        </Button>
        <Button
          className="back-to-login"
          onClick={() => nav('/')}
        >
          Remember your password? Login
        </Button>
      </form>
    );
  }

  if (resetCodeHasBeenSent) {
    return (
      <div className="ResetPassword">
        <MarkEmailReadOutlinedIcon className="email-sent-icon" />
        <h4>Reset link sent</h4>
        <p className="p-body-medium">{`We sent an email to ${userLoginInput.email || verificationEmail} with a link to reset your password.`}</p>
        <Button
          className="send-new-link"
          disabled={isSendingResetEmail}
          onClick={() => handleSendResetCode()}
        >
          Send new reset link
        </Button>
        <Button
          className="back-to-login"
          onClick={() => {
            setUserLoginInput({ email: '', password: '' });
            nav('/');
          }}
        >
          Remember your password? Login
        </Button>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSendResetCode(e)} className="ResetPassword">
      <h4>Forgot password?</h4>
      <p>Enter your email address and we&apos;ll send you a link to reset your password.</p>
      <TextField
        label="Email"
        className="email-field"
        value={userLoginInput.email}
        error={emailInputHasError}
        onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
        onFocus={() => setEmailInputHasError(false)}
        onBlur={() => userLoginInput.email && checkEmailInput()}
        helperText={emailInputHasError ? (
          <>
            <ErrorIcon />
            {emailErrorMessage}
          </>
        ) : ' '}
      />
      <Button
        type="submit"
        className="confirm-reset-btn"
        disabled={isSendingResetEmail}
      >
        {isSendingResetEmail ? <LoadingSpinner className="loading-spinner" /> : 'Send reset link'}
      </Button>
      <Button
        className="back-link"
        onClick={() => nav('/')}
      >
        Back
      </Button>
    </form>
  );
}
