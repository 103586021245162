
// anystring@anystring.anystring
export const isAValidEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

export function checkForEmailErrors(email) {
  let errorStatus = true;
  let errorMessage = '';
  if ((!email.includes('@') || email.split('@').length > 2)) errorMessage = 'An email address must contain a single @';
  else if (!email.split('@')[1].includes('.')) errorMessage = 'The domain portion of the email address is invalid (the portion after @)';
  else if (!isAValidEmail(email)) errorMessage = 'Invalid email entered...';
  else errorStatus = false;
  return { errorStatus, errorMessage };
}

export default {
  isAValidEmail,
  checkForEmailErrors,
};
