import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

// eslint-disable-next-line import/no-cycle
import VerificationCodeSent from '../VerifyEmail/VerificationCodeSent';


import { checkForEmailErrors } from '../../utils';
import { checkIfEmailIsVerified } from '../../utils/apiCalls';

import {
  ErrorMessageContext,
  UserLoginInputContext,
} from '../../lib/contextLib';

import './Login.scss';

export default function Login({ verifiedEmail }) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);

  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [checkingForEmailErrors, setCheckingForEmailErrors] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordInputHasError, setPasswordInputHasError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showLoginErrorHeader, setShowLoginErrorHeader] = useState(false);

  const [showInactivityMessage, setShowInactivityMessage] = useState(false);

  const [isSendingNewCode, setIsSendingNewCode] = useState(false);
  const [newCodeHasBeenSentEmail, setNewCodeHasBeenSentEmail] = useState('');


  const nav = useNavigate();

  async function checkEmailInput() {
    setCheckingForEmailErrors(true);
    let { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    if (!errorStatus) {
      const isEmailVerified = await checkIfEmailIsVerified(userLoginInput.email);
      if (!isEmailVerified) {
        errorStatus = true;
        errorMessage = (
          <span>
            Please verify your email address to proceed.
            {' '}
            <Button
              className="resend-code-error-link"
              disabled={isSendingNewCode}
              onClick={async () => {
                setIsSendingNewCode(true);
                try {
                  await Auth.resendSignUp(userLoginInput.email);
                  setNewCodeHasBeenSentEmail(userLoginInput.email);
                  setUserLoginInput({ email: '', password: '' });
                } catch (e) {
                  setShowErrorMessage(e.toString());
                } finally {
                  setIsSendingNewCode(false);
                }
              }}
            >
              Resend code?
            </Button>
          </span>
        );
      }
    }
    setEmailInputHasError(errorStatus);
    setEmailErrorMessage(errorMessage);
    setCheckingForEmailErrors(false);
  }

  useEffect(() => {
    // Reset all local storage and user context states upon landing on login page
    localStorage.clear();

    if (verifiedEmail) setUserLoginInput({ ...userLoginInput, email: verifiedEmail });
    else if (userLoginInput.email) checkEmailInput();

    setShowInactivityMessage(new URLSearchParams(window.location.search).get('inactivity'));
  }, []);

  function checkForPasswordErrors() {
    if (userLoginInput.password.length < 8) {
      setPasswordInputHasError(true);
      setPasswordErrorMessage(
        <span>
          The password you entered is incorrect. Password must be 8 characters minimum.
          {' '}
          <Button className="forgot-password-error-link" onClick={() => nav('/login/reset')}>Forgot password?</Button>
        </span>,
      );
    } else {
      setPasswordInputHasError(false);
      setPasswordErrorMessage('');
    }
  }

  async function handleLogin(event) {
    event.preventDefault();
    if (userLoginInput.email === '' || userLoginInput.password === '') {
      if (userLoginInput.email === '') {
        setEmailInputHasError(true);
        setEmailErrorMessage('Please enter your email address.');
      }
      if (userLoginInput.password === '') {
        setPasswordInputHasError(true);
        setPasswordErrorMessage('No password entered');
      }
      return;
    }

    if (passwordInputHasError || emailInputHasError || checkingForEmailErrors) return;

    try {
      setIsLoggingIn(true);
      const cognitoResponse = await Auth.signIn(userLoginInput.email, userLoginInput.password);
      if (cognitoResponse.signInUserSession.accessToken.payload['cognito:groups']?.includes('initio-admins')) {
        window.location.replace(process.env.REACT_APP_ADMIN_HOSTNAME);
      } else if (cognitoResponse.signInUserSession.accessToken.payload['cognito:groups']?.includes('enterprise-users')) {
        window.location.replace(process.env.REACT_APP_ENTERPRISE_HOSTNAME);
      } else if (cognitoResponse.signInUserSession.accessToken.payload['cognito:groups']?.includes('investor-users')) {
        window.location.replace(`${process.env.REACT_APP_ENTERPRISE_HOSTNAME}`);
      } else {
        window.location.replace(process.env.REACT_APP_INITIO_HOSTNAME);
      }
    } catch (e) {
      setShowLoginErrorHeader(true);
    } finally {
      setIsLoggingIn(false);
    }
  }

  function loginHeader() {
    if (showInactivityMessage) {
      return (
        <div className="inactivity-header">
          <UpdateIcon />
          <h4>You&apos;ve been logged out due to inactivity</h4>
          <p>All of your progress has been saved, log in to continue.</p>
        </div>
      );
    }
    if (verifiedEmail) {
      return (
        <>
          <h4>Log in to continue</h4>
          <div className="email-verified-header">
            <div className="check-icon">
              <CheckRoundedIcon />
            </div>
            <p>{`Great! Your email ${verifiedEmail} has been verified. Please enter your password to continue.`}</p>
          </div>
        </>
      );
    }
    return <h4>Login</h4>;
  }

  if (newCodeHasBeenSentEmail) return <VerificationCodeSent emailToVerify={newCodeHasBeenSentEmail} />;

  return (
    <main className="Login">

      {loginHeader()}

      {showLoginErrorHeader && (
        <div className="login-error-header">
          <WarningIcon />
          <p>
            We&apos;re having trouble signing you in. Please double check your email
            address and password are correct, then try again.
          </p>
        </div>
      )}

      <form onSubmit={(e) => handleLogin(e)}>
        <TextField
          label="Email"
          className="email-field"
          InputLabelProps={{ shrink: true }}
          value={userLoginInput.email}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
          onFocus={() => setEmailInputHasError(false)}
          onBlur={() => userLoginInput.email && checkEmailInput()}
          error={emailInputHasError}
          helperText={emailInputHasError ? (
            <>
              <ErrorIcon />
              {emailErrorMessage}
            </>
          ) : 'Use your company email address'}
        />

        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          InputLabelProps={{ shrink: true }}
          onFocus={() => setPasswordInputHasError(false)}
          onBlur={() => userLoginInput.password && checkForPasswordErrors()}
          error={passwordInputHasError}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={passwordInputHasError ? (
            <>
              <ErrorIcon />
              {passwordErrorMessage}
            </>
          ) : ' '}
        />

        <Button
          type="submit"
          className="confirm-login-btn"
          disabled={isLoggingIn}
        >
          {isLoggingIn ? <LoadingSpinner className="loading-spinner" /> : 'Login'}
        </Button>
      </form>

      <div className="bottom-link-btns">
        <Button
          className="reset-password-link"
          onClick={() => nav('/login/reset')}
        >
          Forgot password?
        </Button>
        {!verifiedEmail && !showInactivityMessage && (
          <Button
            className="sign-up-link"
            onClick={() => nav('/signup')}
          >
            No account? Sign-up
          </Button>
        )}
      </div>
    </main>
  );
}

Login.propTypes = {
  verifiedEmail: PropTypes.string,
};

Login.defaultProps = {
  verifiedEmail: '',
};
