import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import VerificationCodeSent from '../VerifyEmail/VerificationCodeSent';
import SignupFlow from './SignUpFlow';

import './SignUp.scss';

export default function Signup() {
  const [currentStep, setCurrentStep] = useState(1);
  const [validationCodeSentEmail, setValidationCodeSentEmail] = useState('');

  if (validationCodeSentEmail) return <VerificationCodeSent emailToVerify={validationCodeSentEmail} />;

  return (
    <main className="Signup">
      <h4>Sign up</h4>
      <span className="current-step">{`${currentStep} of 3`}</span>
      <LinearProgress variant="determinate" value={(currentStep / 3) * 100} />
      <SignupFlow currentStep={currentStep} setCurrentStep={setCurrentStep} setValidationCodeSentEmail={setValidationCodeSentEmail} />
    </main>
  );
}
