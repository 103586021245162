import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './PasswordStrengthBar.scss';

export default function PasswordStrengthBar({
  passwordInput,
  passwordStrength,
  setPasswordStrength,
}) {
  const passwordRequirements = [
    {
      text: 'One uppercase character',
      test: (password) => /[A-Z]/g.test(password),
    },
    {
      text: 'One special character',
      test: (password) => /[^A-Za-z0-9]/g.test(password),
    },
    {
      text: 'One lowercase character',
      test: (password) => /[a-z]/g.test(password),
    },
    {
      text: '8 characters minimum',
      test: (password) => password.length >= 8,
    },
    {
      text: 'One number',
      test: (password) => /[0-9]/g.test(password),
    },
  ];

  const passwordStrengthLabels = ['Weak', 'Okay', 'Good', 'Great'];

  useEffect(() => {
    if (!passwordInput) setPasswordStrength('Weak');
    else if (passwordRequirements.every((requirement) => requirement.test(passwordInput))) {
      if (passwordInput.length >= 16) setPasswordStrength('Great');
      else if (passwordInput.length >= 10) setPasswordStrength('Good');
      else setPasswordStrength('Okay');
    } else setPasswordStrength('Weak');
  }, [passwordInput]);

  return (
    <div className="PasswordStrengthBar">

      <div className="password-req-bar">
        {passwordStrengthLabels.map((label, i) => (
          <div
            key={label}
            className={`${passwordInput && passwordStrengthLabels.indexOf(passwordStrength) >= i ? 'fill' : ''} ${passwordStrength}`}
          />
        ))}
      </div>
      <div className={`password-strength-label ${!passwordInput ? 'hidden' : ''} ${passwordStrength} `}>{passwordStrength}</div>

      <div className="req-list">
        {passwordRequirements.map((requirement) => (
          <div key={requirement.text} className={`req-item ${requirement.test(passwordInput) ? 'completed' : ''}`}>
            <div className="req-bullet" />
            <span>{requirement.text}</span>
          </div>
        ))}
      </div>

    </div>
  );
}

PasswordStrengthBar.propTypes = {
  passwordInput: PropTypes.string.isRequired,
  passwordStrength: PropTypes.string.isRequired,
  setPasswordStrength: PropTypes.func.isRequired,
};
