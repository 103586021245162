import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

// eslint-disable-next-line import/no-cycle
import Login from '../Login/Login';

import './VerificationCodeSent.scss';

export default function VerificationCodeSent({ emailToVerify }) {
  const [emailIsVerified, setEmailIsVerified] = useState(false);
  const checkForEmailVerifiedInterval = useRef(null);

  async function isEmailVerified() {
    try {
      let isEmailVerifiedResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/is-verified/${emailToVerify}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
      );
      isEmailVerifiedResponse = await isEmailVerifiedResponse.json();
      if (isEmailVerifiedResponse.userExists && isEmailVerifiedResponse.isVerified) {
        clearInterval(checkForEmailVerifiedInterval.current);
        setEmailIsVerified(true);
      }
    } catch (e) {
      alert(e);
    }
  }

  useEffect(() => {
    if (!emailIsVerified) {
      checkForEmailVerifiedInterval.current = setInterval(() => {
        isEmailVerified();
      }, 5000);
    }
    return () => clearInterval(checkForEmailVerifiedInterval.current);
  }, [emailIsVerified]);

  if (emailIsVerified) return <Login verifiedEmail={emailToVerify} />;

  return (
    <div className="VerificationCodeSent">
      <MarkEmailReadOutlinedIcon className="top-icon" />
      <h4>Verification email sent</h4>
      <p>{`To complete the sign up process you must verify your email address. We have sent a verification email to ${emailToVerify}.`}</p>
    </div>
  );
}

VerificationCodeSent.propTypes = { emailToVerify: PropTypes.string.isRequired };
